import React, { ReactNode, useState } from 'react'
import classNames from 'classnames'
import Header from '../Header/Header'
import styles from './PageLayout.module.scss'
import Footer from '@algbra/ui/components/Footer/Footer'
import { graphql, useStaticQuery } from 'gatsby'

import AnnouncementBar, {
  AnnouncementBarProps,
} from '@algbra/ui/components/AnnouncementBar/AnnouncementBar'

export type theme = 'corporateHome' | 'corporateFounders'

export type PageLayoutProps = {
  children: ReactNode
  theme?: theme
  announcement?: AnnouncementBarProps
  simpleHeader?: boolean
}

const QUERY_FOOTER = graphql`
  query MyQuery {
    strapiGQL {
      corporateFooter(publicationState: LIVE) {
        slogan
        copyright
        disclaimer
        links {
          id
          category
          title
          url
          target
        }
        logos {
          name
          url
          image {
            id
            url
            ext
            width
            height
            resolved {
              publicURL
            }
          }
        }
      }
    }
  }
`

const PageLayout = React.forwardRef<HTMLDivElement, PageLayoutProps>(
  (props, ref) => {
    const {
      strapiGQL: { corporateFooter },
    } = useStaticQuery(QUERY_FOOTER)
    const { theme, children } = props

    return (
      <>
        <div id="portal"></div>
        <div
          ref={ref}
          className={classNames(styles.pageLayout, styles[`theme__${theme}`])}
        >
          <Header theme={theme} />
          {theme !== 'corporateHome' && (
            <AnnouncementBar
              title="SC Ventures makes strategic investment in Algbra, establishes partnership with its UK sustainable finance platform Shoal"
              to="https://www.algbra.com/news/sc-ventures-makes-strategic-investment-in-algbra-establishes-partnership-with-its-uk-sustainable-finance-platform-shoal/"
              target="_blank"
            />
          )}
          <div className={styles.pageLayoutContent}>{children}</div>
          <Footer navigationFooter={corporateFooter} isCorporate={true} />
        </div>
      </>
    )
  }
)

export default PageLayout
