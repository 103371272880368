import React from 'react'
import FreeText from '../../components/FreeText/FreeText'
import { Column, Container, Row } from '../../components/Grid/Grid'
import Heading from '../../components/Heading/Heading'
import Icon from '../../components/Icon/Icon'
import Permalink from '../../components/Permalink/Permalink'
import { HAlign, VAlign } from '../../enums/align'
import { getIconByName } from '../../icons'
import { resolveAsAttachment } from '../../resolvers'
import { nl2br } from '../../text'
import { graphql, useStaticQuery } from 'gatsby'
import { filter, groupBy, includes, isEmpty, isNil, keys, map } from 'rambdax'
import {
  FooterPrimaryLink,
  FooterStoreLinks,
  LogoItem,
  SecondaryLink,
  SocialLink,
} from '../../types/footer'
import styles from './Footer.module.scss'
import FooterLinkGroup from './FooterLinkGroup'

function Footer({ navigationFooter, isCorporate }): JSX.Element {
  if (!navigationFooter) {
    return null
  }
  const {
    slogan,
    links,
    secondaryLinks,
    copyright,
    storeLinks,
    socialLinks,
    disclaimer,
    logos,
  } = navigationFooter

  const groupedLinks = groupBy(
    link => link.category,
    links as FooterPrimaryLink[]
  )
  return (
    <footer className={styles.container}>
      <Container className={styles.primary}>
        <Row>
          <Column size={12} sm={4} className={styles.logo}>
            <Row vAlign={VAlign.BOTTOM} gutter={false}>
              <Column auto={true}>
                <img
                  src="/images/logo-footer.svg"
                  alt="Algbra Logo"
                  className={styles.image}
                  loading="lazy"
                  width={66}
                  height={91}
                />
              </Column>
              <Column>
                <Heading level={3} type="sm" className={styles.text}>
                  <span
                    dangerouslySetInnerHTML={{ __html: nl2br(slogan || '') }}
                  ></span>
                </Heading>
              </Column>
            </Row>
          </Column>
          <Column size={12} sm={8} as="nav" className={styles.groupedLinks}>
            <Row hAlign={HAlign.END}>
              {map(key => {
                const linksByCategory = filter(
                  link => link.category === key,
                  links as FooterPrimaryLink[]
                )
                return (
                  <FooterLinkGroup
                    key={`footer-link-group-${key}`}
                    category={key as string}
                    links={linksByCategory}
                  />
                )
              }, keys(groupedLinks))}
            </Row>
          </Column>
        </Row>
      </Container>

      {socialLinks && (
        <Container className={styles.secondary}>
          <Row vAlign={VAlign.CENTER}>
            <Column size={12} sm={6} className={styles.stores}>
              {map(({ image, url, alternativeText }) => {
                return (
                  <a key={`store-link-${image.id}`} href={url}>
                    <img
                      src={resolveAsAttachment(image)}
                      alt={alternativeText}
                      width={image.width}
                      height={image.height}
                      loading="lazy"
                    />
                  </a>
                )
              }, storeLinks as FooterStoreLinks[])}
            </Column>
            <Column size={12} sm={6} className={styles.social}>
              {map(link => {
                return (
                  <Permalink
                    key={`footer-${link.url}`}
                    to={link.url}
                    isExternal={true}
                    isBlank={true}
                  >
                    <Icon icon={getIconByName(link.iconType)} />
                    <span className="visually-hidden">
                      Social Profile: {link.title}
                    </span>
                  </Permalink>
                )
              }, (socialLinks || []) as SocialLink[])}
            </Column>
          </Row>
        </Container>
      )}

      {disclaimer && (
        <div data-nosnippet>
          <Container>
            <FreeText color="secondary" type="sm" innerHTML={disclaimer}>
              {disclaimer}
            </FreeText>
          </Container>
        </div>
      )}

      {!isCorporate && (
        <Container>
          <div className={styles.logos}>
            {map(
              logo =>
                !isEmpty(logo.url) && !isNil(logo.url) ? (
                  <Permalink
                    key={logo.image.id}
                    to={logo.url}
                    isExternal={true}
                    isBlank={true}
                  >
                    <img
                      src={resolveAsAttachment(logo.image)}
                      alt={logo.name}
                      height={60}
                    />
                  </Permalink>
                ) : (
                  <img
                    key={logo.image.id}
                    src={resolveAsAttachment(logo.image)}
                    alt={logo.name}
                    height={60}
                  />
                ),
              logos as LogoItem[]
            )}
          </div>
        </Container>
      )}

      {!isCorporate && (
        <Container fluid={true} className={styles.legal}>
          <Container>
            <Row vAlign={VAlign.CENTER}>
              <Column size={12} sm={4} className={styles.country}>
                <img
                  src="/images/flags/uk.svg"
                  alt="Flag: United Kingdom"
                  width={24}
                  height={24}
                  loading="lazy"
                />
                <span>United Kingdom</span>
              </Column>
              <Column
                size={12}
                sm={8}
                as="nav"
                className={styles.links}
                aria-label="Footer Navigation"
              >
                <ul>
                  {map(
                    ({ url, title, target }) => (
                      <li key={`secondary-links-${url}`}>
                        {includes('http', url) ? (
                          <a href={url} target={target}>
                            {title}
                          </a>
                        ) : (
                          <Permalink to={url}>{title}</Permalink>
                        )}
                      </li>
                    ),
                    (secondaryLinks || []) as SecondaryLink[]
                  )}
                </ul>
              </Column>
            </Row>
          </Container>
        </Container>
      )}

      <Container className={styles.copyright}>
        <FreeText color="secondary" type="sm" as="span">
          {copyright}
        </FreeText>
      </Container>
    </footer>
  )
}

export default Footer
