import React, { useEffect, useRef, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import classNames from 'classnames'

import Permalink from '@algbra/ui/components/Permalink/Permalink'
import Icon from '@algbra/ui/components/Icon/Icon'
import { getIconByName } from '@algbra/ui/icons'

import styles from './Header.module.scss'
import { theme } from '../PageLayout/PageLayout'
import { ReactComponent as HeaderLogo } from '../../../static/svg/headerLogo.svg'

const QUERY_HEADER_NAVIGATION = graphql`
  query NavigationQuery {
    strapiGQL {
      corporateHeader {
        items {
          id
          title
          url
          target
          groupName
        }
        subLinks {
          id
          title
          description
          url
          parent
          isBanner
          target
          icon {
            id
            url
            ext
            resolved {
              publicURL
            }
          }
        }
      }
    }
  }
`

export type HeaderProps = {
  theme?: theme
}

const HOVER_MENU_WIDTH = 295
const PAGE_OFFSET_LIMIT = 60

function Header({ theme }: HeaderProps) {
  const [hasBlur, setHasBlur] = useState(false)
  const [activeHeaderItemId, setActiveHeaderItemId] = useState('')
  const headerItemRefs = useRef([])
  const closeHoverMenuTimerId = useRef(null)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const {
    strapiGQL: { corporateHeader },
  } = useStaticQuery(QUERY_HEADER_NAVIGATION)

  const mobileMenuIconName = showMobileMenu ? 'times' : 'burger'

  useEffect(() => {
    if (window.pageYOffset > PAGE_OFFSET_LIMIT) {
      setHasBlur(true)
    } else {
      setHasBlur(false)
    }
  }, [])

  useEffect(() => {
    function handleScroll(e) {
      if (window.pageYOffset > PAGE_OFFSET_LIMIT) {
        setHasBlur(true)
      } else {
        setHasBlur(false)
      }
    }

    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
  }, [showMobileMenu])

  const getSublinksOfParent = () => {
    const currentParent = corporateHeader.items.find(
      item => item.id === activeHeaderItemId
    )

    return corporateHeader.subLinks.filter(
      sublink => sublink.parent === currentParent.groupName
    )
  }

  const handleCloseHoverMenu = () => {
    closeHoverMenuTimerId.current = window.setTimeout(() => {
      setActiveHeaderItemId(null)
    }, 100)
  }

  const calculatePosition = () => {
    const parentsLeftPoint =
      headerItemRefs.current[activeHeaderItemId].getBoundingClientRect().left

    return parentsLeftPoint + HOVER_MENU_WIDTH > window.innerWidth
      ? window.innerWidth - (HOVER_MENU_WIDTH + 20)
      : parentsLeftPoint
  }

  return (
    <div
      className={classNames(styles.wrapper, [styles[`theme__${theme}`]], {
        [styles.hasBlur]: hasBlur,
      })}
    >
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Permalink to="/">
            <HeaderLogo />
          </Permalink>
        </div>

        <div className={styles.headerItemWrapper}>
          {corporateHeader.items.map(item => {
            return (
              <div
                key={item.id}
                className={styles.headerItem}
                ref={e => (headerItemRefs.current[item.id] = e)}
                onMouseEnter={() => {
                  clearTimeout(closeHoverMenuTimerId.current)
                  setActiveHeaderItemId(item.id)
                }}
                onMouseLeave={() => {
                  handleCloseHoverMenu()
                }}
              >
                {item.title}
              </div>
            )
          })}
        </div>

        <div className={styles.mobileNav}>
          <div
            className={classNames(styles.mobileMenuIcon, {
              [styles.open]: showMobileMenu,
            })}
            onClick={() => setShowMobileMenu(prev => !prev)}
          >
            <Icon icon={getIconByName(mobileMenuIconName)} size={24} />
          </div>
        </div>
      </div>

      <div
        className={styles.headerMenuContainer}
        style={{
          pointerEvents: activeHeaderItemId ? 'auto' : 'none',
          opacity: activeHeaderItemId ? 1 : 0,
          transform: activeHeaderItemId ? 'rotateX(0deg)' : 'rotateX(-15deg)',
        }}
      >
        {activeHeaderItemId && (
          <div
            className={styles.hoverMenu}
            style={{
              top: 0,
              left: calculatePosition(),
            }}
            onMouseEnter={() => {
              clearTimeout(closeHoverMenuTimerId.current)
            }}
            onMouseLeave={() => {
              handleCloseHoverMenu()
            }}
          >
            {getSublinksOfParent().map(sublink => {
              return (
                <Permalink
                  key={sublink.title}
                  className={styles.hoverMenuItem}
                  to={sublink.url}
                  isBlank={sublink.target === 'blank'}
                  isExternal={sublink.target === 'blank'}
                >
                  {sublink.title}
                </Permalink>
              )
            })}
          </div>
        )}
      </div>

      {showMobileMenu && (
        <>
          <div className={styles.overlay} />

          <div className={styles.mobileMenu}>
            {corporateHeader.items.map(item => {
              return (
                <div key={item.id} className={styles.modileMenuBlock}>
                  <div className={styles.mobileMenuTitle}>{item.title}</div>

                  {corporateHeader.subLinks.map(sublink => {
                    if (sublink.parent === item.groupName) {
                      return (
                        <Permalink
                          key={sublink.title}
                          className={styles.mobileMenuItem}
                          to={sublink.url}
                          isBlank={sublink.target === 'blank'}
                          isExternal={sublink.target === 'blank'}
                        >
                          {sublink.title}
                        </Permalink>
                      )
                    } else {
                      return null
                    }
                  })}
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default Header
